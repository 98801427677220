import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CockpitContainer from './CockpitContainer';
import Twitter from '../images/tweet_generator.webp';
import Instagram from '../images/instagram_generator.webp';
import Facebook from '../images/facebook_generator.webp';
import LinkedIn from '../images/linkedin_generator.webp';
import Artikel from '../images/article_generation.webp';
import Blog from '../images/blog_generator.webp';
import Mail from '../images/mail_generator.webp';

const contentTypes = [
  { name: 'Twitter', image: Twitter },
  { name: 'Instagram', image: Instagram },
  { name: 'Facebook', image: Facebook },
  { name: 'LinkedIn', image: LinkedIn },
  { name: 'Artikel', image: Artikel },
  { name: 'Blog', image: Blog },
  { name: 'E-Mail', image: Mail },
];

const ContentTypeSelector = ({ onClose, article }) => {
  const [selectedType, setSelectedType] = useState(null);

  if (selectedType) {
    return <CockpitContainer onClose={onClose} article={article} initialTab={contentTypes.findIndex(type => type.name === selectedType)} />;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-4xl overflow-hidden">
        <div className="p-4 relative">
          <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 z-10" onClick={onClose}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div className="space-y-4">
            {contentTypes.map((type, index) => (
              <div 
                key={index} 
                className="w-full h-24 bg-cover bg-center relative rounded-lg overflow-hidden cursor-pointer transition-transform transform hover:scale-105 shadow-md"
                style={{ backgroundImage: `url(${type.image})` }}
                onClick={() => setSelectedType(type.name)}
              >
                <div className="absolute inset-0 bg-white bg-opacity-80 flex items-center justify-center">
                  <h3 className="text-gray-800 text-xl font-bold">{type.name}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTypeSelector;