import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';

const TopicManagement = () => {
  const [topics, setTopics] = useState([]);
  const [topicName, setTopicName] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [contentLanguage, setContentLanguage] = useState('german');
  const [message, setMessage] = useState('');
  const [editState, setEditState] = useState({});
  const [loading, setLoading] = useState(false);
  const [feedbackMessages, setFeedbackMessages] = useState({});
  const [languages, setLanguages] = useState([{ code: '', bingSearchTerm: '', googleSearchTerm: '' }]);

  const fetchTopics = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/topic/get-all`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch topics');
      }
      const data = await response.json();
      data.sort((a, b) => a.id - b.id);
      setTopics(data);
    } catch (error) {
      console.error('Error fetching topics:', error);
      setMessage('Failed to fetch topics');
    }
  };

  useEffect(() => {
    fetchTopics();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!topicName || languages.some(lang => !lang.code)) {
      setMessage('Please fill in all required fields');
      return;
    }

    setLoading(true);
    const response = await fetch(`${config.apiUrl}/topic/create`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: topicName,
        thumbnail_url: thumbnailUrl,
        content_language: contentLanguage,
        languages: languages
      }),
    });

    setLoading(false);
    if (response.ok) {
      setMessage('Topic created successfully');
      setTopicName('');
      setThumbnailUrl('');
      setLanguages([{ code: '', bingSearchTerm: '', googleSearchTerm: '' }]);
      fetchTopics();
    } else {
      const errorMessage = await response.text();
      setMessage(`Failed to create topic: ${errorMessage}`);
    }
  };

  const handleEditChange = (topicId, field, value) => {
    setEditState(prev => ({
      ...prev,
      [topicId]: {
        ...prev[topicId],
        [field]: value,
      },
    }));
  };

  const handleLanguageChange = (index, field, value) => {
    const newLanguages = [...languages];
    newLanguages[index][field] = value;
    setLanguages(newLanguages);
  };

  const addLanguage = () => {
    setLanguages([...languages, { code: '', bingSearchTerm: '', googleSearchTerm: '' }]);
  };

  const removeLanguage = (index) => {
    const newLanguages = languages.filter((_, i) => i !== index);
    setLanguages(newLanguages);
  };

  const handleUpdate = async (topicId) => {
    setLoading(true);
    const topicToUpdate = editState[topicId];
    const response = await fetch(`${config.apiUrl}/topic/update`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: topicId, ...topicToUpdate }),
    });

    setLoading(false);
    if (response.ok) {
      setFeedbackMessages(prev => ({ ...prev, [topicId]: 'Update successful' }));
      setEditState(prev => {
        const newState = { ...prev };
        delete newState[topicId];
        return newState;
      });
      fetchTopics();
    } else {
      const errorMessage = await response.text();
      setFeedbackMessages(prev => ({ ...prev, [topicId]: `Failed to update: ${errorMessage}` }));
    }
  };

  const handleAggregateClick = async (topicName) => {
    setLoading(true);
    setFeedbackMessages(prev => ({ ...prev, [topicName]: '' }));
    const encodedTopicName = encodeURIComponent(topicName).toLowerCase();
    setFeedbackMessages(prev => ({ ...prev, [topicName]: 'Aggregating...' }));
    const response = await fetch(`${config.apiUrl}/aggregate-articles/${encodedTopicName}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      setFeedbackMessages(prev => ({ ...prev, [topicName]: 'Aggregation successful' }));
    } else {
      const errorMessage = await response.text();
      setFeedbackMessages(prev => ({ ...prev, [topicName]: `Failed to aggregate: ${errorMessage}` }));
    }
    setLoading(false);
    fetchTopics();
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <Link className="text-blue-600 hover:text-blue-800 underline mb-4 inline-block" to="/admin">Back to Admin Dashboard</Link>
  
      <div className="bg-white shadow rounded-lg p-6 mb-8">
        <h2 className="text-2xl font-bold mb-4 text-gray-900">Create a New Topic</h2>
        {message && <p className="text-sm font-medium text-red-600 mb-4">{message}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="topicName" className="block text-sm font-medium text-gray-700">Topic Name</label>
            <input
              id="topicName"
              type="text"
              value={topicName}
              onChange={(e) => setTopicName(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="thumbnailUrl" className="block text-sm font-medium text-gray-700">Thumbnail URL</label>
            <input
              id="thumbnailUrl"
              type="text"
              value={thumbnailUrl}
              onChange={(e) => setThumbnailUrl(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>
          {languages.map((lang, index) => (
            <div key={index} className="space-y-2">
              <div>
                <label htmlFor={`locale-${index}`} className="block text-sm font-medium text-gray-700">Locale</label>
                <select
                  id={`locale-${index}`}
                  value={lang.code}
                  onChange={(e) => handleLanguageChange(index, 'code', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                >
                  <option value="">Select Locale</option>
                  <option value="en-US">English (United States)</option>
                  <option value="de-AT">German (Austria)</option>
                </select>
              </div>
              <div>
                <label htmlFor={`bingSearchTerm-${index}`} className="block text-sm font-medium text-gray-700">Bing Search Term</label>
                <input
                  id={`bingSearchTerm-${index}`}
                  type="text"
                  value={lang.bingSearchTerm}
                  onChange={(e) => handleLanguageChange(index, 'bingSearchTerm', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor={`googleSearchTerm-${index}`} className="block text-sm font-medium text-gray-700">Google Search Term</label>
                <input
                  id={`googleSearchTerm-${index}`}
                  type="text"
                  value={lang.googleSearchTerm}
                  onChange={(e) => handleLanguageChange(index, 'googleSearchTerm', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              {index > 0 && (
                <button
                  type="button"
                  onClick={() => removeLanguage(index)}
                  className="mt-2 px-3 py-1 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  Remove Language
                </button>
              )}
            </div>
          ))}
          <button
            type="button"
            onClick={addLanguage}
            className="mt-2 px-3 py-1 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            Add Language
          </button>
          <button 
            type="submit" 
            disabled={loading}
            className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
          >
            Create Topic
          </button>
        </form>
      </div>
  
      <h2 className="text-2xl font-bold mb-4 text-gray-900">Manage Topics</h2>
      <div className="overflow-x-auto bg-white shadow rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {['ID', 'Name', 'Languages', 'Thumbnail', 'Last Aggregated', 'Active', 'Actions'].map((header) => (
                <th key={header} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {topics.map((topic) => (
              <tr key={topic.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{topic.id}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <input
                    type="text"
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    value={editState[topic.id]?.name || topic.name}
                    onChange={(e) => handleEditChange(topic.id, 'name', e.target.value)}
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {topic.languages.map((lang, index) => (
                    <div key={index} className="mb-2">
                      <select
                        value={editState[topic.id]?.languages?.[index]?.code || lang.code}
                        onChange={(e) => handleEditChange(topic.id, 'languages', topic.languages.map((l, i) => i === index ? {...l, code: e.target.value} : l))}
                        className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      >
                        <option value="en-US">English (United States)</option>
                        <option value="de-AT">German (Austria)</option>
                      </select>
                      <input
                        type="text"
                        placeholder="Bing Search Term"
                        value={editState[topic.id]?.languages?.[index]?.bing_search_term || lang.bing_search_term}
                        onChange={(e) => handleEditChange(topic.id, 'languages', topic.languages.map((l, i) => i === index ? {...l, bing_search_term: e.target.value} : l))}
                        className="mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                      <input
                        type="text"
                        placeholder="Google Search Term"
                        value={editState[topic.id]?.languages?.[index]?.google_search_term || lang.google_search_term}
                        onChange={(e) => handleEditChange(topic.id, 'languages', topic.languages.map((l, i) => i === index ? {...l, google_search_term: e.target.value} : l))}
                        className="mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                  ))}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <input
                    type="text"
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    value={editState[topic.id]?.thumbnail_url || topic.thumbnail_url}
                    onChange={(e) => handleEditChange(topic.id, 'thumbnail_url', e.target.value)}
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{topic.last_aggregated}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <input
                    type="checkbox"
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    checked={editState[topic.id]?.active ?? topic.active}
                    onChange={(e) => handleEditChange(topic.id, 'active', e.target.checked)}
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button 
                    onClick={() => handleUpdate(topic.id)} 
                    disabled={loading}
                    className="mr-2 px-3 py-1 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:opacity-50"
                  >
                    Update
                  </button>
                  <button 
                    onClick={() => handleAggregateClick(topic.name)} 
                    disabled={loading}
                    className="px-3 py-1 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
                  >
                    Aggregate
                  </button>
                  <span className="ml-2 text-xs text-gray-600">{feedbackMessages[topic.id] || feedbackMessages[topic.name]}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TopicManagement;